<template>
  <div>
    <NavigationBar
      showBackBtn
      titel="Details"
      :actions="actions"
    ></NavigationBar>
    <section>
      <template>
        <v-container>
          <v-row>
            <v-col cols="12" sm="12" md="6">
              <time-sheet-details-card
                :loading="helpers.dataIsLoading"
                :timeSheet="timeSheet"
              ></time-sheet-details-card>
            </v-col>
            <v-col cols="12" sm="12" md="6">
              <v-skeleton-loader
                v-if="helpers.dataIsLoading"
                elevation="2"
                type="card-heading, list-item, divider, article, divider, actions"
              ></v-skeleton-loader>
              <v-row v-else class="match-height">
                <v-col cols="6">
                  <statistics-single-card
                    title="Geleistete Zeit"
                    color="blue-grey"
                    icon="mdi-timer-sand-complete"
                    :value="getTimeSheetDurationText()"
                  ></statistics-single-card>
                </v-col>
                <v-col cols="6">
                  <statistics-single-card
                    title="Abrechenbare Zeit"
                    color="blue-grey"
                    icon="mdi-timer-sand-complete"
                    :value="getTimeSheetDurationText()"
                  ></statistics-single-card>
                </v-col>
                <v-col cols="6">
                  <!-- <statistics-single-card
                    title="Teilnehmer"
                    color="blue-grey"
                    icon="mdi-counter"
                    :value="timeSheet.attendances.count.totalPresent"
                  ></statistics-single-card> -->
                </v-col>
              </v-row>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12">
              <Subheader icon="mdi-history" title="Logs"> </Subheader>
              <div class="">
                <v-timeline dense align-top>
                  <v-timeline-item
                    fill-dot
                    class="mb-2"
                    color="white"
                    icon-color="grey"
                    icon="mdi-pencil"
                    small
                  >
                    <v-card>
                      <v-card-text>
                        <v-textarea
                          v-model="helpers.commentForm.value"
                          dense
                          label="Kommentar hinzufügen"
                          hide-details="auto"
                          outlined
                          no-resize
                          auto-grow
                          rows="3"
                        >
                        </v-textarea>
                        <v-alert
                          v-if="helpers.commentForm.errorMessage"
                          dense
                          type="error"
                          text
                          class="mt-4 mb-0"
                        >
                          {{ helpers.commentForm.errorMessage }}</v-alert
                        >
                      </v-card-text>
                      <v-divider></v-divider>
                      <v-card-actions>
                        <!-- <v-checkbox
                          dense
                          disabled
                          label="Benachrichtigung per E-Mail senden"
                          persistent-hint
                          hint="Eine Benachrichtigung über diesen Eintrag wird per E-Mail an ausgewählte Personen gesendet."
                        ></v-checkbox> -->

                        <v-spacer></v-spacer
                        ><v-btn
                          class="mx-0"
                          color="success"
                          depressed
                          :loading="helpers.commentForm.isLoading"
                          :disabled="!helpers.commentForm.value"
                          @click="addComment"
                        >
                          Hinzufügen
                        </v-btn>
                      </v-card-actions>
                    </v-card>
                  </v-timeline-item>

                  <v-slide-x-transition group>
                    <div
                      v-for="entry in logsAndComments"
                      :key="entry.id"
                      class="mb-2"
                    >
                      <v-timeline-item
                        color="white"
                        fill-dot
                        small
                        :icon-color="logEntryActionColor(entry.type)"
                        :icon="logEntryActionIcon(entry.type)"
                      >
                        <v-card flat class="elevation-2">
                          <v-card-text>
                            <v-row justify="space-between">
                              <v-col cols="7">
                                <strong>{{ entry.user.displayName }}</strong>
                                {{ logEntryActionText(entry.type) }}
                                <span v-if="entry.type === 'comment'">
                                  <br />{{ entry.text }}
                                </span>
                                <span v-if="entry.reason">
                                  <br />Grund: {{ entry.reason }}
                                </span></v-col
                              >
                              <v-col class="text-right" cols="5">{{
                                timestampConverter(entry.timestamp)
                              }}</v-col>
                            </v-row>
                          </v-card-text>
                        </v-card>
                      </v-timeline-item>
                    </div>
                  </v-slide-x-transition>
                  <v-divider></v-divider>
                </v-timeline>
              </div>
            </v-col>
          </v-row>
        </v-container>
      </template>
    </section>
    <custom-dialog
      v-if="helpers.approveTimeSheetDialog.model"
      @close="toggleApproveTimeSheetDialog()"
      title="Dientbericht genehmigen"
      text="Änderungen müssen vor der Genehmigung durchgeführt werden. Die Genehmigung wird dann entsprechend gekennzeichnet (Genehmigt mit Änderungen)."
      width="s"
      :errorMessage="helpers.approveTimeSheetDialog.errorMessage"
      :loading="helpers.approveTimeSheetDialog.isLoading"
      :btnAction="approveTimeSheet"
      btnText="Genehmigen"
      fullscreenOnMobile
    >
      <template v-slot:content>
        <v-form
          v-model="helpers.approveTimeSheetDialog.formIsValid"
          ref="approveDialogForm"
          @submit.prevent="approveTimeSheet()"
          ><v-textarea
            v-model="helpers.approveTimeSheetDialog.reasonValue"
            outlined
            label="Begründung/Vermerk"
            auto-grow
            persistent-hint
            hint="Optional"
            class="mt-2 pb-0 m0-0"
          ></v-textarea>
        </v-form>
      </template>
    </custom-dialog>
    <custom-dialog
      v-if="helpers.moveBackToReviewDialog.model"
      @close="toggleMoveBackToReviewDialog()"
      title="Dientbericht zur Überprüfung übergeben"
      width="s"
      :errorMessage="helpers.moveBackToReviewDialog.errorMessage"
      :loading="helpers.moveBackToReviewDialog.isLoading"
      :btnAction="moveBackToReview"
      fullscreenOnMobile
    >
      <template v-slot:content>
        <v-form
          v-model="helpers.moveBackToReviewDialog.formIsValid"
          ref="allowMoveBackToReviewForm"
          @submit.prevent="moveBackToReview()"
          ><v-textarea
            v-model="helpers.moveBackToReviewDialog.reasonValue"
            outlined
            label="Begründung/Vermerk"
            auto-grow
            persistent-hint
            hint="Optional"
            class="mt-2 pb-0 m0-0"
          ></v-textarea>
        </v-form>
      </template>
    </custom-dialog>
    <custom-dialog
      v-if="helpers.rejectTimeSheetDialog.model"
      @close="toggleRejectTimeSheetDialog()"
      title="Dientbericht ablehnen"
      width="s"
      :errorMessage="helpers.rejectTimeSheetDialog.errorMessage"
      :loading="helpers.rejectTimeSheetDialog.isLoading"
      :btnAction="rejectTimeSheet"
      :btnDisabled="!helpers.rejectTimeSheetDialog.formIsValid"
      btnText="Ablehnen"
      fullscreenOnMobile
    >
      <template v-slot:content>
        <v-form
          v-model="helpers.rejectTimeSheetDialog.formIsValid"
          ref="rejectDialogForm"
          @submit.prevent="rejectTimeSheet()"
          ><v-textarea
            v-model="helpers.rejectTimeSheetDialog.reasonValue"
            outlined
            label="Begründung/Vermerk"
            auto-grow
            required
            :rules="[rules.required]"
            hide-details="auto"
            class="mt-2 pb-0 m0-0"
          ></v-textarea>
        </v-form>
      </template>
    </custom-dialog>
    <v-snackbar
      v-model="helpers.snackbar.isVisible"
      class="pb-4"
      bottom
      app
      timeout="3000"
      :color="helpers.snackbar.color"
      ><v-icon class="mr-1">mdi-check-circle</v-icon>
      {{ helpers.snackbar.message }}
    </v-snackbar>
    <section v-if="checkIfSupport">
      <support-tools :sources="code"></support-tools>
    </section>
  </div>
</template>

<script>
import { db, auth, FieldValue, Timestamp, functions } from "@/firebase";
import SupportTools from "@/components/_system/helpers/SupportTools.vue";
import NavigationBar from "@/components/_systemwide/NavigationBar.vue";
import Subheader from "@/components/_systemwide/Subheader.vue";
import CustomDialog from "@/components/_systemwide/CustomDialog.vue";
import TimeSheetDetailsCard from "@/components/accounting/time-sheets/TimeSheetDetailsCard.vue";
import StatisticsSingleCard from "@/components/statistics/StatisticsSingleCard.vue";
export default {
  name: "accounting-time-sheets-details",
  components: {
    NavigationBar,
    Subheader,
    TimeSheetDetailsCard,
    SupportTools,
    StatisticsSingleCard,
    CustomDialog,
  },
  data() {
    return {
      rules: {
        required: (value) => !!value || "Feld ist erforderlich.",
      },
      helpers: {
        dataIsLoading: false,
        approveTimeSheetDialog: {
          model: false,
          errorMessage: "",
          isLoading: false,
          reasonValue: "",
        },
        rejectTimeSheetDialog: {
          model: false,
          errorMessage: "",
          isLoading: false,
          reasonValue: "",
        },
        moveBackToReviewDialog: {
          model: false,
          errorMessage: "",
          isLoading: false,
          reasonValue: "",
        },
        snackbar: {
          message: "",
          isVisible: false,
        },
        commentForm: {
          isLoading: false,
          value: "",
          errorMessage: "",
        },
      },
      timeSheet: null,
      logs: [],
      comments: [],
    };
  },
  computed: {
    actions() {
      const timeSheetStatus = this.timeSheet ? this.timeSheet.status : null;
      const actions = [{ divider: true }, { actionStyle: "clipboardBtn" }];
      const approveAction = {
        title: "Genehmigen",
        icon: "mdi-check-circle-outline",
        color: "success",
        actionStyle: "textBtnSubmit",
        function: this.toggleApproveTimeSheetDialog,
      };
      const rejectAction = {
        title: "Ablehnen",
        // permission: `${SCHEDULING_CONFIG_EVENT_TYPES_delete}`,
        icon: "mdi-close-circle-outline",
        actionStyle: "textBtnSubmit",
        color: "error",
        function: this.toggleRejectTimeSheetDialog,
      };
      const editAction = {
        title: "Bearbeiten",
        // permission: `${SCHEDULING_CONFIG_EVENT_TYPES_delete}`,
        icon: "mdi-pencil",
        actionStyle: "textBtn",
        function: () => {
          this.$router.push({
            name: "accounting-time-sheets-edit",
            params: {
              organizationId: this.$route.params.organizationId,
              itemId: this.$route.params.itemId,
            },
          });
        },
      };
      const allowReviewAction = {
        title: "Erneute Prüfung",
        // permission: `${SCHEDULING_CONFIG_EVENT_TYPES_delete}`,
        icon: "mdi-check-all",
        actionStyle: "textBtn",
        color: "purple",
        function: this.toggleMoveBackToReviewDialog,
      };
      const allowEditAction = {
        title: "Bearbeitung erforderlich",
        // permission: `${SCHEDULING_CONFIG_EVENT_TYPES_delete}`,
        icon: "mdi-pencil-box-multiple",
        actionStyle: "textBtnSubmit",
        color: "orange",
        // function: () => {
        //   this.$router.push({
        //     name: "duty-management-timeSheets-live",
        //     params: {
        //       organizationId: this.$route.params.organizationId,
        //       itemId: this.$route.params.itemId,
        //     },
        //   });
        // },
      };
      if (timeSheetStatus === "inProgress") {
        actions.unshift(editAction);
      } else if (timeSheetStatus === "toBeReviewed") {
        actions.unshift(rejectAction);
        actions.unshift(approveAction);
      } else if (
        timeSheetStatus === "approved" ||
        timeSheetStatus === "rejected"
      ) {
        actions.unshift(allowReviewAction);
        if (timeSheetStatus === "rejected") {
          actions.unshift(allowEditAction);
        }
      }
      return actions;
    },
    checkIfSupport() {
      return this.$store.getters["user/checkIfSupport"];
    },
    code() {
      return [
        { title: "timeSheet", data: this.timeSheet },
        { title: "logsAndComments", data: this.logsAndComments },
      ];
    },
    logsAndComments() {
      return this.logs
        .concat(this.comments)
        .sort((a, b) => b.timestamp - a.timestamp);
    },
  },
  created() {
    this.initialize();
  },
  watch: {
    $route: "initialize",
  },
  methods: {
    initialize() {
      this.getTimeSheet();
      this.getLogs();
      this.getComments();
      this.helpers.dataIsLoading = false;
    },
    getTimeSheet() {
      return db
        .collection("organizations")
        .doc(this.$route.params.organizationId)
        .collection("timeSheets")
        .doc(this.$route.params.itemId)
        .get()
        .then((doc) => {
          if (doc.exists) {
            this.timeSheet = doc.data();
          } else {
            // doc.data() will be undefined in this case
            console.log("No such document!");
          }
        })
        .catch(function (error) {
          console.log("Error getting document:", error);
          return false;
        });
    },
    getLogs() {
      return db
        .collection("organizations")
        .doc(this.$route.params.organizationId)
        .collection("timeSheets")
        .doc(this.$route.params.itemId)
        .collection("logs")
        .get()
        .then((querySnapshot) => {
          const logs = [];
          querySnapshot.forEach((doc) => {
            logs.push(doc.data());
          });
          this.logs = logs;
        })
        .catch(function (error) {
          console.log("Error getting document:", error);
          return false;
        });
    },
    getComments() {
      return db
        .collection("organizations")
        .doc(this.$route.params.organizationId)
        .collection("timeSheets")
        .doc(this.$route.params.itemId)
        .collection("comments")
        .get()
        .then((querySnapshot) => {
          const comments = [];
          querySnapshot.forEach((doc) => {
            comments.push(doc.data());
          });
          this.comments = comments;
        })
        .catch(function (error) {
          console.log("Error getting document:", error);
          return false;
        });
    },
    addComment() {
      if (this.helpers.commentForm.value != "") {
        this.helpers.commentForm.isLoading = true;
        const commentRef = db
          .collection("organizations")
          .doc(this.$route.params.organizationId)
          .collection("timeSheets")
          .doc(this.$route.params.itemId)
          .collection("comments")
          .doc();

        const delay = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

        return commentRef
          .set({
            user: {
              displayName: auth.currentUser.displayName,
              uid: auth.currentUser.uid,
            },
            type: "comment",
            text: this.helpers.commentForm.value,
            mentions: [],
            timestamp: FieldValue.serverTimestamp(),
            id: commentRef.id,
          })
          .then(async () => {
            this.getComments();
            await delay(800);
            this.helpers.commentForm.value = "";
            this.helpers.commentForm.isLoading = false;
          })
          .catch(async (error) => {
            console.error("Error adding document: ", error);
            await delay(800);
            this.helpers.commentForm.errorMessage = error.message;
            this.helpers.commentForm.isLoading = false;
          });
      } else {
        this.helpers.commentForm.errorMessage =
          "Fehler: Es sind nicht alle Pflichtangaben ausgefüllt.";
      }
    },
    logEntryActionText(type) {
      switch (type) {
        case "created":
          return "hat den Dienstbericht erstellt.";
        case "approved":
          return "hat den Dienstbericht genehmigt.";
        case "toBeReviewed":
          return "hat den Dienstbericht zur Prüfung übergeben.";
        case "rejected":
          return "hat den Dienstbericht abgelehnt.";
        case "comment":
          return "hat einen Kommentar hinzugefügt:";
        case "reassigned":
          return "hat den Dienstbericht neu zugewiesen.";
        case "completed":
          return "hat den Dienstbericht geschlossen.";
        default:
          break;
      }
    },
    logEntryActionColor(type) {
      switch (type) {
        case "created":
          return "primary";
        case "approved":
        case "completed":
          return "green";
        case "toBeReviewed":
          return "purple";
        case "rejected":
          return "red";
        case "reassigned":
          return "primary";
        case "comment":
          return "grey";
        default:
          break;
      }
    },
    logEntryActionIcon(type) {
      switch (type) {
        case "created":
          return "mdi-plus";
        case "approved":
          return "mdi-check";
        case "toBeReviewed":
          return "mdi-check-all";
        case "rejected":
          return "mdi-close";
        case "reassigned":
          return "mdi-account-switch";
        case "comment":
          return "mdi-comment-text";
        case "completed":
          return "mdi-archive-check";
        default:
          break;
      }
    },
    timestampConverter(timestamp) {
      const options = {
        weekday: "short",
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
        hour: "2-digit",
        minute: "2-digit",
      };
      return timestamp.toDate().toLocaleDateString("de-DE", options);
    },
    getAttendanceStatusText(statusId) {
      switch (statusId) {
        case "1":
          return "Anwesend";
        case "2":
          return "Entschuldigt";
        case "3":
          return "Unentschuldigt";
        default:
          return "Fehler";
      }
    },
    getTimeSheetDurationText() {
      // Retrieve the start and end timestamps
      const startTimestamp = this.timeSheet.workedDuration.start.timestamp;
      let endTimestamp = this.timeSheet.workedDuration.end.timestamp;

      // If endTimestamp is null, use the current timestamp
      if (!endTimestamp) {
        endTimestamp = Timestamp.now();
      }

      // Calculate the duration in milliseconds
      const durationMs = endTimestamp - startTimestamp;
      console.log(durationMs);

      // Convert the duration to hours and minutes
      const durationMinutes = Math.floor(durationMs / 60);
      const hours = Math.floor(durationMinutes / 60);
      const minutes = durationMinutes % 60;

      // Return the duration in a readable format
      return `${hours}h ${minutes}min`;
    },
    toggleApproveTimeSheetDialog() {
      this.helpers.approveTimeSheetDialog.model =
        !this.helpers.approveTimeSheetDialog.model;
      this.helpers.approveTimeSheetDialog.errorMessage = "";
      this.helpers.approveTimeSheetDialog.reasonValue = "";
    },
    toggleRejectTimeSheetDialog() {
      this.helpers.rejectTimeSheetDialog.model =
        !this.helpers.rejectTimeSheetDialog.model;
      this.helpers.rejectTimeSheetDialog.errorMessage = "";
      this.helpers.rejectTimeSheetDialog.reasonValue = "";
    },
    approveTimeSheet() {
      this.helpers.approveTimeSheetDialog.isLoading = true;
      const callFunction = functions.httpsCallable(
        "dutyManagement-approveTimeSheet"
      );
      return callFunction({
        organizationId: this.$route.params.organizationId,
        dutyTimeSheetId: this.timeSheet.meta.id,
        reason: this.helpers.approveTimeSheetDialog.reasonValue,
      })
        .then(() => {
          this.getTimeSheet();
          this.getLogs();
          this.helpers.approveTimeSheetDialog.isLoading = false;
          this.toggleApproveTimeSheetDialog();
          this.helpers.snackbar.message = "Dienstbericht genehmigt.";
          this.helpers.snackbar.isVisible = true;
        })
        .catch((error) => {
          this.helpers.approveTimeSheetDialog.isLoading = false;
          this.helpers.approveTimeSheetDialog.errorMessage = error.message;
          console.error("Error approving timeSheet: ", error);
        });
    },
    rejectTimeSheet() {
      this.$refs.rejectDialogForm.validate();
      if (this.helpers.rejectTimeSheetDialog.formIsValid) {
        this.helpers.rejectTimeSheetDialog.isLoading = true;
        const callFunction = functions.httpsCallable(
          "dutyManagement-rejectTimeSheet"
        );
        return callFunction({
          organizationId: this.$route.params.organizationId,
          dutyTimeSheetId: this.timeSheet.meta.id,
          reason: this.helpers.rejectTimeSheetDialog.reasonValue,
        })
          .then(() => {
            this.getTimeSheet();
            this.getLogs();
            this.helpers.rejectTimeSheetDialog.isLoading = false;
            this.toggleRejectTimeSheetDialog();
            this.helpers.snackbar.message = "Dienstbericht abgelehnt.";
            this.helpers.snackbar.isVisible = true;
          })
          .catch((error) => {
            this.helpers.rejectTimeSheetDialog.isLoading = false;
            this.helpers.rejectTimeSheetDialog.errorMessage = error.message;
            console.error("Error approving timeSheet: ", error);
          });
      } else {
        this.helpers.rejectTimeSheetDialog.errorMessage =
          "Fehler: Es sind nicht alle Pflichtangaben auusgefüllt.";
      }
    },
  },
};
</script>
